import React, { useContext } from 'react';
import profileImage from '../../../assets/imgs/005.png'; 
import { AuthContext } from '../../../context/AuthContext';
import './index.css'; 

const Profile = () => {
  const { user, logout } = useContext(AuthContext);
  console.log(user)
  return (
    <div className='profile'>
      <img className='profile-img' src={profileImage} alt="profile" />
      <div className='profile-text'>
        <div className='profile-text-user'>{user.nome_usuario}</div>
        <div>{user.role}</div>
      </div>
    </div>
  );
};

export default Profile;
