import React, { useRef, useState  } from 'react';
import Modal from 'react-modal';
import { FiX } from "react-icons/fi";
import { AiOutlineCopy } from "react-icons/ai";
import { API_ADM } from '../../../services/ApiAdmin';
import './index.css';

const ModalInscricao = ({ isOpen, closeModal }) => {

  const textareaRef  = useRef(null);
  const [token, setToken] = useState('');
  const baseURL = process.env.REACT_APP_API_ADM;

  const gerarToken = async () => {
    try {
      const response = await API_ADM.post(`${baseURL}/assinatura/token/gerar`);
      const novoToken = response.data.access_token; 
      setToken(novoToken);
    
      // local
      // const linkInscricao = `http://localhost:3000/inscricao?token-insc=${novoToken}`; 

      // producao
      const linkInscricao = `${process.env.REACT_APP_WEB_ADMIN}/inscricao?token=${novoToken}`; 
      
      

      textareaRef.current.value = linkInscricao;
    } catch (error) {
      console.error('Erro ao gerar token:', error.message);
    }
  };


  const handleCopyToClipboard = () => {
    if (textareaRef .current) {
      textareaRef .current.select();
      document.execCommand('copy');
    }
  };


  return (
    <div className={`container-modal ${isOpen ? 'modal-open' : ''}`}>
      <Modal 
        isOpen={isOpen} 
        onRequestClose={closeModal} 
        overlayClassName="modal-overlay"
        className="modal-content"
        contentLabel="Inscrição de Revenda">
        
        <div className='modal-header'>
            <h3>Inscrição de Revenda</h3>      
            <button onClick={closeModal} className='button-dark-close'><FiX /></button>                         
        </div>        
        <div className='modal-body'>
            <div className='modal-main'>               
              <button onClick={gerarToken} className='modal-button-insc button-dark'>Gerar link de inscrição</button>               
              <div className='modal-textarea'>              
                  <div className='modal-textarea-itens'>          
                    <textarea ref={textareaRef}  className="textarea" name='textarea-inscricao' readOnly /> 
                    <AiOutlineCopy onClick={handleCopyToClipboard} />             
                  </div>                               
              </div>                  
            </div>                  
        </div>
        
        
      </Modal>
    </div>
  );
};

export default ModalInscricao;
