import React, { useContext, useState, useEffect  } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import { FiUser, FiLock } from "react-icons/fi";
import logo from '../../../assets/imgs/logo.png';
import { ToastContext } from '../../common/toast/ToastContext';
import Spinner from '../../common/spinner/Spinner';
import axios from 'axios';
import './index.css';


const LoginInscricao = () => {
  const { login } = useContext(AuthContext); 
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { showToast } = useContext(ToastContext);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tokenFromURL = searchParams.get('token');

  

  const handleLogin = async (event) => {
    event.preventDefault();

    const loginData = {
      username,
      password
    };

    try {
      setLoading(true); 
      const response = await axios.post(`${process.env.REACT_APP_API_AUTH_INSC}/auth/inscricao/login`, loginData, {        
        headers: {
          Authorization: `Bearer ${tokenFromURL}`, 
        },        
      });



      const data = response.data;
      
      login(data.access_token); 
      showToast('Login sucedido!', 3000, 'success');
      window.location.href = 'https://alphapdv.com.br/';
      
    } catch (error) {
      const status = error.response.status;
      const errorMessage = error.response.data.message;

      if (status === 401) {
        showToast(`Revenda já Inscrita! : ${errorMessage}`, 3000, 'error');
      } else if (status === 403) {
        showToast(`Credenciais inválidas! : ${errorMessage}`, 3000, 'error');
      } else {
        showToast(`Inscrição falhou! : ${errorMessage}`, 3000, 'error');
  
      }
      
    } finally {
      setLoading(false); 
    }




    
  };


  return (
    <div className='container-login'>
   
      <div className='longin-left'>
         <h1>Inscrição de Revenda</h1>
         <h3>passport para Alpha Tecnologia</h3>
         <br />
         <p>Nesta seção é possivel a incrição de revenda da Hotline no serviço de controle ALPHA,
            onde é possível, receber recorrência mensal de seus cliente de forma digital,
            por meio de QRCode. Controle de backup em nuvem de XML/Banco de dados. Fique atento ao tempo do toker, que expira em 5 minutos.
         </p>
        
      </div>

      <div className='longin-rigth'>
       
         <div className='login-body'>
          
           <div className='login-header'>
                 <img src={logo} alt='sidebar-logo'/>            
           </div>


           <div className='login-form'>
              <form onSubmit={handleLogin}>
                <div className='input-user'>


                <label id="email-label" for="email" class="chakra-form__label css-1p72pwq">Email
                   <span role="presentation" aria-hidden="true" class="chakra-form__required-indicator css-1tfjd1n">*</span>
                </label>


                  <div className='input-box-user'>
                    <FiUser className='input-icon' />
                    <input 
        
                      type='text' 
                      placeholder='Username' 
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      name="user"
          
                    /> 
                  </div>
                  <div className='input-box-pass'>                   
                    <label id="password-label" for="password" class="chakra-form__label css-1p72pwq">Senha
                      <span role="presentation" aria-hidden="true" class="chakra-form__required-indicator css-1tfjd1n">*</span>
                    </label>

                    <FiLock className='input-icon' />
                    <input 
                      type='password' 
                      placeholder='Password' 
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                    /> 
                  </div>
                </div>

                 
                  <button className='button-dark' type='submit'>Login</button> 
                  <div className='form-observacao'>Use as credencias que logra na Hotline!</div>
                  
              
              </form>
         </div>
          
          </div> 
      </div>

      {loading && ( <div className="overlay"> <Spinner /> </div> )}

    </div>
  );
};

export default LoginInscricao;
