import React, { useEffect, useState } from 'react';
import { FiSearch } from "react-icons/fi";
import TableRevendas from '../../../components/common/table/TableRevendas';
import ModalInscricao from '../../../components/common/modal/ModalInscricao';
import Input from '../../../components/common/input/Input';
import Spinner from '../../../components/common/spinner/Spinner';
import Container from '../../../components/common/container/Container';
import ContainerHeader from '../../../components/common/container/ContainerHeader';
import { API_ADM } from '../../../services/ApiAdmin';
import './index.css';


const Revenda = () => {
  const [records, setRecords] = useState([]);
  const [searchValue, setSearchValue] = useState(''); 
  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);


  

  useEffect(() => {
    fetchData('');
  }, []);

  const fetchData = async (value) => {
    try {
      setLoading(true); // Ativar spinner
      const response = await API_ADM.get(`/revendas/get?fragment=${value}`);

      setRecords(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Desativar spinner
    }
  };

  const searchInpuChange = (event) => {
    setSearchValue(event.target.value); 
  };

  const searchButtonClick = () => {
    fetchData(searchValue); 
  };
 
  const openModal = () => {
    setIsModal(true);
  };

  const closeModal = () => {
    setIsModal(false);
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchData(searchValue); 
    }
};
  
  
  return (

    <Container>           
        <ContainerHeader title="Revendas"> 
            <div className='revenda-header-search'>                
                  <div className='revenda-header-itens'>
                    <Input searchInputChange={searchInpuChange} onKeyDown={handleKeyDown} placeholder="Digite alguma coisa..." name="fragment" icon={<FiSearch  />}/>
                    <button onClick={searchButtonClick} className='button'>Pesquisar</button>                                                    
                  </div>

                  <div className='revenda-header-btn-inscricao'>
                      <button onClick={openModal} className='button-dark'>Inscrição de Revenda</button>                                
                  </div>  

            </div>
        </ContainerHeader>
    
              
       {loading ? <Spinner /> : <TableRevendas data={records.revendas} count={records.count} />}     
       <ModalInscricao isOpen={isModal} closeModal={closeModal} />
      
    </Container>

  );
};

export default Revenda;
