import { Link } from "react-router-dom";
import { CgReorder , CgHome, CgKeyboard    } from 'react-icons/cg'
import { FiPaperclip, FiFileMinus, FiPhoneCall, FiUser} from "react-icons/fi";
import logo from '../../../assets/imgs/logo_small.png';
import React, { useState } from 'react';
import './index.css';

const Sidebar = () => {
   const [activeLink, setActiveLink] = useState('');


 
   const handleLinkClick = (linkId) => {
     setActiveLink(linkId);
   };


    return (  
        <div>
          
         
          <div className='sidebar-container'>      
            <div className='sidebar-header'>
              
               <img src={logo} alt='sidebar-logo'/>
            
               <div className='sidebar-header-text'>
                  <div className='sidebar-logo-text1'>Alpha</div>
                  <div className='sidebar-logo-text2'>tecnologia</div>
               </div>
               
            </div>


            <div>
              <div>
              <ul>
                  <li className={`sidebar-nav-link ${activeLink === 'dashboard' ? 'active' : ''}`} onClick={() => handleLinkClick('dashboard')}>
                    <Link to='dashboard'>
                      <i><CgHome /></i>
                      <div className='nav-link'>Dashboard</div>
                    </Link>
                  </li>
                  <li className={`sidebar-nav-link ${activeLink === 'revendas' ? 'active' : ''}`} onClick={() => handleLinkClick('revendas')}>
                    <Link to='revendas'>
                      <i><CgReorder /></i>
                      <div className='nav-link'>Revendas</div>
                    </Link>
                  </li>
                  <li className={`sidebar-nav-link ${activeLink === 'despesas' ? 'active' : ''}`} onClick={() => handleLinkClick('despesas')}>
                    <a href='#'>
                      <i><FiFileMinus /></i>
                      <div className='nav-link'>Despesas</div>
                    </a>
                  </li>
                  <li className={`sidebar-nav-link ${activeLink === 'suporte' ? 'active' : ''}`} onClick={() => handleLinkClick('suporte')}>
                    <a href='#'>
                      <i><FiPhoneCall /></i>
                      <div className='nav-link'>Suporte</div>
                    </a>
                  </li>
                  <li className={`sidebar-nav-link ${activeLink === 'financeiro' ? 'active' : ''}`} onClick={() => handleLinkClick('financeiro')}>
                    <a href='#'>
                      <i><CgKeyboard /></i>
                      <div className='nav-link'>Financeiro</div>
                    </a>
                  </li>
                  <li className={`sidebar-nav-link ${activeLink === 'cobrancas' ? 'active' : ''}`} onClick={() => handleLinkClick('cobrancas')}>
                    <a href='#'>
                      <i><FiPaperclip /></i>
                      <div className='nav-link'>Cobranças</div>
                    </a>
                  </li>
                  <li className={`sidebar-nav-link ${activeLink === 'users' ? 'active' : ''}`} onClick={() => handleLinkClick('users')}>
                    <Link to='users'>
                      <i><FiUser /></i>
                      <div className='nav-link'>Usuários</div>
                    </Link>
                  </li>
</ul>


              </div>

            </div>
          </div>
        </div>    
        );
}
 
export default Sidebar;