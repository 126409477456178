import React from 'react';
import './index.css';


const User = () => {
  return (
     <div className='user-container'>     
      Usuario
     </div>

  );
};

export default User;
