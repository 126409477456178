// import { BrowserRouter, Routes, Route } from "react-router-dom";

import {createBrowserRouter, RouterProvider} from "react-router-dom";

import Home from "./pages/layout/home/Home";
import LoginPage from "./pages/layout/login/LoginPage";
import LoginInscricaoPage from "./pages/layout/inscricao/LoginInscricaoPage";
import NoPage from "./pages/layout/nopage/NoPage";
import User from "./pages/user/User";
import Revenda from "./pages/revenda/main/Revenda";
import RevendaDetalhe from "./pages/revenda/detalhe/RevendaDetalhe";
import RouteSecurity from "./context/RouteSecurity";



const Rotas = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RouteSecurity><Home /></RouteSecurity>,
      children:[
        {
          path: "users",
          element: <RouteSecurity><User /></RouteSecurity>
        },
        {
          path: "revendas",
          element: <RouteSecurity><Revenda /></RouteSecurity>         
        },
        {
          path: "revendas/detalhe",
          element: <RouteSecurity><RevendaDetalhe /></RouteSecurity>
        },
        
      ]
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "inscricao",
      element: <LoginInscricaoPage />,
    },
    {
      path: "*",
      element: <NoPage />,
    },
  ]);




  return (
    <RouterProvider router={router} />     
      
  );
};

export default Rotas;