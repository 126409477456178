import React, { useState } from 'react';
import './index.css';

const TabControl = ({ tabsData }) => {
    const [activeTab, setActiveTab] = useState(0);
  
    const handleTabClick = (index) => {
      setActiveTab(index);
      // Você pode adicionar lógica adicional aqui, se necessário
      // Por exemplo, chamar uma função passada como prop ou carregar conteúdo dinamicamente
    };
  
    return (
        <div className="tab-container">
        <div className='bloc-tabs'>  
          {tabsData.map((tab, index) => (
            <div
              key={index}
              className={`tabs ${index === activeTab ? 'tabs active-tabs' : ''}`}
              onClick={() => handleTabClick(index)}
            >
              {tab.title}
            </div>
          ))}
        </div>
        <div className="tab-content">
          {tabsData[activeTab].content}
        </div>
      </div>
    );
  };

  export default TabControl;