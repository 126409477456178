import React, { useEffect, useState } from 'react';
import { FiSearch } from "react-icons/fi";
// import TableRevendas from '../../../components/common/table/TableRevendas';
import Input from '../../../components/common/input/Input';
// import Spinner from '../../../components/common/spinner/Spinner';
import Container from '../../../components/common/container/Container';
import ContainerHeader from '../../../components/common/container/ContainerHeader';
import { API_ADM } from '../../../services/ApiAdmin';
// import Tab from '../../../components/common/tabs/Tab';
import TabControl from '../../../components/common/tabs/TabControl';
import './index.css';


const RevendaDetalhe = () => {
  const [records, setRecords] = useState([]);
  const [searchValue, setSearchValue] = useState(''); 
  const [loading, setLoading] = useState(false);
  const baseURL = process.env.REACT_APP_API_ADM;

  

  useEffect(() => {
    fetchData('');
  }, []);

  const fetchData = async (value) => {
    try {
      setLoading(true); // Ativar spinner
      const response = await API_ADM.get(`/revendas/get?fragment=${value}`);

      setRecords(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Desativar spinner
    }
  };

  const searchInpuChange = (event) => {
    setSearchValue(event.target.value); 
  };

  const searchButtonClick = () => {
    fetchData(searchValue); 
  };
 
 
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchData(searchValue); 
    }
  };

  const tabsData = [
    {
      title: 'Ativos',
      content: 'Conteúdo da Ativos',
      onClick: () => console.log('Clicou na Ativos'),
    },
    {
      title: 'Inativos',
      content: 'Conteúdo da Inativos',
      onClick: () => console.log('Clicou na Inativos'),
    }
  ];
  
  
  return (

    <Container>           
        <ContainerHeader title="Revendas"> 
            <div className='revenda-header-search'>                
                  <div className='revenda-header-itens'>
                    <Input searchInputChange={searchInpuChange} onKeyDown={handleKeyDown} placeholder="Digite alguma coisa..." name="fragment" icon={<FiSearch  />}/>
                    <button onClick={searchButtonClick} className='button'>Pesquisar</button>                                                    
                  </div>

                 

            </div>
        </ContainerHeader>
    
       {/* <Tab /> */}
       <TabControl tabsData={tabsData} />
                          
    </Container>

  );
};

export default RevendaDetalhe;
