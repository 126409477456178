import React from 'react';
import { Outlet} from "react-router-dom";
import Sidebar from '../sidebar/Sidebar';
import Profile from '../../common/profile/Profile';
import { FiBell} from "react-icons/fi";
import Logout from "../../layout/logout/Logout";

import './index.css';



const Layout = () => {
  return (
     <div className='container dashboard-container'>

       <div className='header'>
          <div className='text-panel'>Painel Administrativo</div>
          <div className='header-right'>
          
            <div className='notications'>
              <i><FiBell /></i>
              <span>1</span>
            </div>

            <div className='profile'>
                <Profile />
            </div>
            
            <div>
               <Logout />                                     
            </div>
          </div>
       </div>

       <div  className='nav sidebar-layout'><Sidebar /></div>
       <div  className='main'><Outlet /> </div>
       <div  className='aside'>Conteudo Relacionado</div>
       <div  className='footer'>Alpha tecnologia 2023 - Todos os direitos reservados</div>
     </div>

  );
};

export default Layout;
